<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <form @submit.prevent="confirmUpdate()" action="">
      <ion-row>
        <ion-col size="12">
          <ion-list>
            <ion-item lines="none">
              <ion-label
                ><ion-text>
                  <h2>Select From Class</h2>
                </ion-text></ion-label
              >
            </ion-item>
            <ion-item v-if="validationError" lines="none">
              <ion-label className="ion-text-wrap">
                <ion-text color="danger">
                  <p v-for="error of v$.$errors" :key="error.$uid">
                    <ion-icon
                      :ios="alertCircleOutline"
                      :md="alertCircleSharp"
                      size="small"
                      style="vertical-align: middle"
                    ></ion-icon
                    >{{ error.$property }}: {{ error.$message }}
                  </p></ion-text
                >
              </ion-label>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.standard.$error }">
              <ion-label position="floating">Standard</ion-label>
              <ion-select v-model="standard" @ionChange="fetchStudents(true)">
                <ion-select-option
                  v-for="standard of standards"
                  :key="standard.id"
                  :value="standard.id"
                >
                  Standard {{ standard.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.section.$error }">
              <ion-label position="floating">Section</ion-label>
              <ion-select v-model="section" @ionChange="fetchStudents()">
                <ion-select-option
                  v-for="section of sections"
                  :key="section.id"
                  :value="section.id"
                >
                  Section {{ section.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.student_ids.$error }">
              <ion-label position="floating">Students</ion-label>
              <ion-select
                v-model="student_ids"
                multiple
                :disabled="disable_student_selection"
                :interface-options="{ cssClass: 'full-screen' }"
              >
                <ion-select-option
                  v-for="student of students"
                  :key="student.id"
                  :value="student.id"
                >
                  {{
                    `${student.section_standard.section.name}-${student.roll_no} ${student.user.user_detail.name}`
                  }}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item lines="none" class="ion-margin-top">
              <ion-label
                ><ion-text>
                  <h2>Select To Class</h2>
                </ion-text></ion-label
              >
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.to_standard.$error }">
              <ion-label position="floating">Standard</ion-label>
              <ion-select v-model="to_standard">
                <ion-select-option
                  v-for="standard of standards"
                  :key="standard.id"
                  :value="standard.id"
                >
                  Standard {{ standard.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item :class="{ 'color-danger': v$.to_section.$error }">
              <ion-label position="floating">Section</ion-label>
              <ion-select v-model="to_section">
                <ion-select-option
                  v-for="section of sections"
                  :key="section.id"
                  :value="section.id"
                >
                  Section {{ section.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button type="submit" expand="full" class="ion-margin-vertical">
            Save
          </ion-button>
        </ion-col>
      </ion-row>
    </form>
  </base-layout>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonIcon,
  IonButton,
  loadingController,
  alertController,
  IonText,
  toastController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, integer, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import BaseLayout from "../../Base/BaseLayout";

export default {
  emits: ["closeModal"],
  components: {
    BaseLayout,
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonIcon,
    IonButton,
    IonText,
  },
  data() {
    return {
      title: "Transfer students",
      backLink: "setup",
      reloadable: false,

      standard: null,
      section: null,

      student_ids: null,

      to_standard: null,
      to_section: null,

      alertCircleOutline,
      alertCircleSharp,

      disable_student_selection: true,

      validationError: null,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      standard: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
      section: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
      student_ids: {
        required: helpers.withMessage("is required", required),
      },
      to_standard: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
      to_section: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
    };
  },

  computed: {
    standards() {
      return this.$store.getters["standard/allResources"];
    },
    sections() {
      return this.$store.getters["section/allResources"];
    },

    students() {
      return this.$store.getters["student/studentsBySectionStandard"];
    },
  },

  created() {
    this.fetchStandards();
    this.fetchSections();
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });
      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    toaster(message) {
      const toast = toastController.create({
        message,
        duration: 6000,
        color: "primary",
      });

      return toast;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmUpdate() {
      const header = `Confirm`;
      const message =
        "Are you sure you want to re-enlist student and save changes?";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.updateStudent();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async updateStudent() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        student_ids: this.student_ids,
        standard_id: this.to_standard,
        section_id: this.to_section,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("student/transferStudent", data);
        spinner.dismiss();

        this.standard = null;
        this.section = null;
        this.to_standard = null;
        this.to_section = null;
        this.student_ids = null;
        this.disable_student_selection = true;

        const toast = await this.toaster("Students transfered successfully");
        await toast.present();

      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();
      }
    },
    async fetchStudents(reset = false) {
      if (reset == true) {
        this.section = null;
        this.disable_student_selection = true;
        return;
      }
      if (this.standard == "" || this.section == "") {
        return;
      }
      const payLoad = {
        standard_id: this.standard,
        section_id: this.section,
      };
      this.disable_student_selection = true;
      await this.$store.dispatch("student/studentsBySectionStandard", payLoad);
      this.disable_student_selection = false;
    },

    async fetchStandards() {
      await this.$store.dispatch("standard/allResources");
    },

    async fetchSections() {
      await this.$store.dispatch("section/allResources");
    },
  },
};
</script>